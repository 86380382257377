
















import { Observer } from "mobx-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Observer
@Component({
  components: {},
})
export default class MysteryBoxWithId extends Vue {
  @Prop({ default: 120 }) imageSize!: number;
  @Prop({ default: "" }) boxId!: string;
}
